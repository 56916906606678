import KpnService from '@/services/KpnService.js';

export default {
  namespaced: true,
  state: {
    schedules: []
  },
  mutations: {
    SET_SCHEDULES(state, schedules) {
      state.schedules = schedules;
    },
    DELETE_SCHEDULE(state, id) {
      state.schedules = state.schedules.filter((schedule) => schedule.id !== id);
    }
  },
  actions: {
    searchSchedules({ dispatch, commit }, body) {
      return KpnService.searchSchedules(body)
        .then((response) => commit('SET_SCHEDULES', response.data))
        .catch((error) => {
          console.error(error.message);
          dispatch('notifications/addNotification', { message: 'Failed to get schedules', type: 'error' }, { root: true });
        });
    },
    deleteSchedule({ dispatch, commit }, id) {
      return KpnService.deleteSchedule(id)
        .then(() => commit('DELETE_SCHEDULE', id))
        .catch((error) => {
          console.error(error.message);
          dispatch('notifications/addNotification', { message: 'Failed to delete schedule', type: 'error' }, { root: true });
        });
    }
  },
  getters: {}
};
