<template>
  <v-navigation-drawer :clipped="$vuetify.breakpoint.lgAndUp" app v-bind="$attrs" v-on="$listeners" v-if="loggedIn">
    <Me />
    <v-divider></v-divider>
    <v-list dense>
      <v-list-item v-for="link in links" :key="link.name" :to="link.to" link>
        <v-list-item-icon>
          <v-icon>{{ link.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ link.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import Me from '@/components/Me.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'NavigationDrawer',
  components: {
    Me
  },
  data() {
    return {
      links: [
        { name: 'Calendar', to: { name: 'Calendar' }, icon: 'mdi-calendar' },
        { name: 'Import', to: { name: 'Import' }, icon: 'mdi-calendar-import' }
      ]
    };
  },
  computed: {
    ...mapGetters('auth', ['loggedIn'])
  }
};
</script>
