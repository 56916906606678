export default {
  namespaced: true,
  state: {
    notifications: [],
    show: false,
    timeout: 5000
  },
  mutations: {
    ADD_NOTIFICATION(state, notification) {
      state.notifications = [notification, ...state.notifications];
      state.show = true;
      state.timeout += 1;
    },
    UPDATE_NOTIFICATION(state, value) {
      state.show = value;
    }
  },
  actions: {
    addNotification({ commit }, notification) {
      commit('ADD_NOTIFICATION', notification);
    },
    updateNotification({ commit }, value) {
      commit('UPDATE_NOTIFICATION', value);
    }
  },
  getters: {
    notification: (state) => {
      return state.notifications[0];
    }
  }
};
