<template>
  <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="white" elevation="2">
    <v-app-bar-nav-icon @click="handleNavIconClick" />
    <v-toolbar-title>KPN Web Scheduler</v-toolbar-title>
  </v-app-bar>
</template>

<script>
export default {
  name: 'AppBar',
  methods: {
    handleNavIconClick() {
      this.$emit('drawer');
    }
  }
};
</script>
