<template>
  <v-fab-transition>
    <v-btn color="primary" fab v-show="show" v-bind="$attrs" v-on="$listeners"><slot /></v-btn>
  </v-fab-transition>
</template>

<script>
export default {
  name: 'ActionButton',
  data() {
    return {
      show: false
    };
  },
  created() {
    setTimeout(() => (this.show = true), 1000);
  }
};
</script>
