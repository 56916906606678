<template>
  <v-snackbar v-model="snackbar" :timeout="timeout" :left="$vuetify.breakpoint.mdAndUp" v-if="notification">
    <strong :class="classes">{{ notification.message }}</strong>
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="snackbar = false"><strong :class="classes">Close</strong></v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'NotificationBar',
  computed: {
    ...mapState('notifications', ['show', 'timeout']),
    ...mapGetters('notifications', ['notification']),
    snackbar: {
      get() {
        return this.show;
      },
      set(value) {
        this.$store.dispatch('notifications/updateNotification', value);
      }
    },
    classes() {
      return [this.notification.type === 'error' ? 'red--text' : 'green--text'];
    }
  }
};
</script>
