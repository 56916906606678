import GenesysService from '@/services/GenesysService.js';

export default {
  namespaced: true,
  state: {
    me: null,
    permittedDivisions: {
      result: {
        entities: []
      }
    }
  },
  mutations: {
    SET_ME(state, me) {
      state.me = me;
    },
    SET_PERMITTED_DIVISIONS(state, data) {
      state.permittedDivisions.result = data;
    }
  },
  actions: {
    async getMe({ commit }) {
      try {
        const response = await GenesysService.getMe();
        const groupsResponse = await Promise.all(response.data.groups.map((group) => GenesysService.getUri(group.selfUri)));
        response.data.groups = groupsResponse.map((group) => group.data);
        commit('SET_ME', response.data);
      } catch (error) {
        console.error(error.message);
      }
    },
    async getPermittedDivisions({ dispatch, commit }) {
      try {
        const response = await GenesysService.getDivisionsPermitted('directory:user:view');
        commit('SET_PERMITTED_DIVISIONS', response.data);
      } catch (error) {
        console.error(error.message);
        dispatch(
          'notifications/addNotification',
          { message: 'Failed to get permitted divisions', type: 'error' },
          { root: true }
        );
      }
    }
  },
  getters: {
    permittedDivisionIds: (state) => {
      return state.permittedDivisions.result.entities.map((division) => division.id);
    },
    groupIds: (state) => {
      return state.me.groups.filter((group) => group.name.startsWith(process.env.VUE_APP_GROUPS_PREFIX)).map((group) => group.id);
    },
    isLoaded: (state) => {
      return state.permittedDivisions.result.total >= 0 && !!state.me;
    }
  }
};
