<template>
  <v-autocomplete
    v-model="selectedQueue"
    :loading="loading"
    :items="queues"
    :search-input.sync="search"
    cache-items
    label="Queue Filter"
    hide-details
    flat
    solo-inverted
    clearable
    dense
    item-text="name"
    return-object
    prepend-inner-icon="mdi-filter"
  ></v-autocomplete>
</template>

<script>
import { mapState } from 'vuex';
import debounce from 'lodash/debounce';

export default {
  name: 'QueueSelection',
  data() {
    return {
      search: null,
      queueName: null
    };
  },
  created() {
    this.$store.dispatch('genesys/queues/getQueues');
  },
  computed: {
    ...mapState('genesys/queues', ['queues', 'loading']),
    selectedQueue: {
      get() {
        return this.$store.state.genesys.queues.selectedQueue;
      },
      set(queue) {
        if (queue) this.queueName = queue.name;
        this.$store.commit('genesys/queues/SET_SELECTED_QUEUE', queue);
      }
    }
  },
  watch: {
    search(val) {
      val && val !== this.queueName && this.handleSearchChange(val);
    }
  },
  methods: {
    handleSearchChange: debounce(function (val) {
      this.searchQueues(val);
    }, 500),
    searchQueues(search) {
      this.$store.dispatch('genesys/queues/getQueues', search);
    }
  }
};
</script>
