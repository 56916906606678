import axios from 'axios';
import store from '@/store';

const genesys = axios.create({
  baseURL: 'https://api.mypurecloud.de'
});

genesys.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch('auth/logout');
    }
    return Promise.reject(error);
  }
);

export default genesys;
