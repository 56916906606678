import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/store/modules/auth.js';
import genesys from '@/store/modules/genesys.js';
import notifications from '@/store/modules/notifications.js';
import schedules from '@/store/modules/schedules.js';
import bulk from '@/store/modules/bulk.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    genesys,
    notifications,
    schedules,
    bulk
  },
  strict: process.env.NODE_ENV === 'development'
});
