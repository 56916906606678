import kpn from '@/axios/kpn';
import genesys from '@/axios/genesys';

export default {
  namespaced: true,
  state: {
    auth: null
  },
  mutations: {
    SET_AUTH(state, auth) {
      state.auth = auth;
    }
  },
  actions: {
    updateAuth({ commit }, auth) {
      kpn.defaults.headers.common['Authorization'] = `Bearer ${auth.kpn.access_token}`;
      genesys.defaults.headers.common['Authorization'] = `Bearer ${auth.genesys.access_token}`;
      commit('SET_AUTH', auth);
    },
    logout() {
      window.location.reload();
    }
  },
  getters: {
    loggedIn: (state) => !!state.auth
  }
};
