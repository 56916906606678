import Vue from 'vue';
import VueRouter from 'vue-router';
import Callback from '@/views/Callback.vue';
import Calendar from '@/views/Calendar.vue';
import store from '@/store';
import redirect from '@/lib/redirect';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'Calendar' }
  },
  {
    path: '/callback',
    name: 'Callback',
    component: Callback
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar,
    meta: { requiresAuth: true }
  },
  {
    path: '/import',
    name: 'Import',
    component: () => import(/* webpackChunkName: "import" */ '@/views/Import.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/addschedules',
    name: 'AddSchedules',
    component: () => import(/* webpackChunkName: "addschedules" */ '@/views/AddSchedules.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/health',
    name: 'Health',
    component: () => import(/* webpackChunkName: "health" */ '@/views/Health.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const loggedIn = store.getters['auth/loggedIn'];
  if (to.meta.requiresAuth && !loggedIn) redirect();
  else next();
});

export default router;
