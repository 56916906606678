import me from './genesys/me.js';
import users from './genesys/users.js';
import queues from './genesys/queues.js';

export default {
  namespaced: true,
  modules: {
    me,
    users,
    queues
  }
};
