import kpn from '@/axios/kpn';

export default {
  addSchedules(schedules, deleteActiveSchedules) {
    const body = {
      deleteActiveSchedules,
      schedules
    };
    return kpn.post('/schedules', body);
  },
  searchSchedules(body) {
    return kpn.post(`/schedules/search`, body);
  },
  deleteSchedule(id) {
    return kpn.delete(`/schedules/${id}`);
  }
};
