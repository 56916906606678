<template>
  <v-container fluid v-if="isLoaded">
    <SchedulesCalender />
    <ActionButton bottom right fixed @click="$router.push({ name: 'AddSchedules' })">
      <v-icon>mdi-calendar-plus</v-icon>
    </ActionButton>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import SchedulesCalender from '@/components/SchedulesCalender.vue';
import ActionButton from '@/components/ActionButton.vue';

export default {
  name: 'Calender',
  components: {
    SchedulesCalender,
    ActionButton
  },
  computed: {
    ...mapGetters('genesys/me', ['isLoaded'])
  }
};
</script>
