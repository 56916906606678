import auth from '@/axios/auth';

export default {
  getAccessToken(code, redirectUri, app) {
    const body = {
      code,
      redirectUri,
      app
    };
    return auth.post('/token', body);
  }
};
